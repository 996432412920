import { Button, CardActionArea, CardActions } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import * as React from "react";

const ProjectCard = ({ name, github, site, description, img }) => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={img}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <a href={github} target="_blank" rel="noreferrer">
          <Button variant="outlined" color="success" size="large">
            Github
          </Button>
        </a>
        <a href={site} target="_blank" rel="noreferrer">
          <Button variant="outlined" color="success" size="large">
            Website
          </Button>
        </a>
      </CardActions>
    </Card>
  );
};

export default ProjectCard;
