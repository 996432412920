import React from "react";
import Contact from "../../components/Contact.component";
import Hero from "../../components/Hero.component";
import Projects from "../../components/Projects.component";
import Skills from "../../components/Skills.component";
import WhoAmI from "../../components/WhoAmI.component";

const Home = () => {
  return (
    <>
      <Hero />
      <WhoAmI />
      <Skills />
      <Projects />
      <Contact />
    </>
  );
};
export default Home;
