import { Container } from "@mui/material";
import React from "react";
import allProjects from "../lib/projects.json";
import "../styles/projects.style.scss";
import ProjectCard from "./ProjectCard.component";

const Projects = () => {
  return (
    <>
      <h1 style={{ textAlign: "center" }}>Projects</h1>
      <Container className="projects_container">
        {allProjects.map(({ name, github, site, description, img }) => (
          <ProjectCard
            title={name}
            description={description}
            github={github}
            site={site}
            img={img}
          />
        ))}
      </Container>
    </>
  );
};

export default Projects;
