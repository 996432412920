import { Container } from "@mui/material";
import React from "react";
import SkillImage from "../assets/Coding-01.png";
import "../styles/skills.style.scss";

const Skills = () => {
  return (
    <Container className="skills_container">
      <h1>Skills</h1> <br />
      <img src={SkillImage} alt="" />
    </Container>
  );
};

export default Skills;
