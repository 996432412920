import React from "react";

const Footer = () => {
  return (
    <div
      style={{
        textAlign: "center",
        padding: "10px",
      }}
    >
      <hr /> <br />
      <h4>Made with 💖 by Daiyan </h4>
    </div>
  );
};

export default Footer;
