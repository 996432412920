import { Navigate, Route, Routes } from "react-router-dom";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import Blog from "./components/Blog.component";
import Contact from "./components/Contact.component";
import Footer from "./components/Footer.component";
import Navbar from "./components/Navbar.component";
import Projects from "./components/Projects.component";
import Skills from "./components/Skills.component";
import WhoAmI from "./components/WhoAmI.component";
import particleJSON from "./lib/particles.json";
import Home from "./routes/Home/Home";

const App = () => {
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };
  return (
    <>
      <Particles
        id="tsparticles"
        options={particleJSON}
        init={particlesInit}
        loaded={particlesLoaded}
      />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Navigate to="/" />} />
        <Route path="/about" element={<WhoAmI />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
