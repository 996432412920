import emailjs from "@emailjs/browser";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import swal from "sweetalert";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_iekn8ha",
        "template_13u4jvh",
        form.current,
        "user_uX0WLPlXyyOqeIqz1V8ZF"
      )
      .then(
        (result) => {
          swal("Success!", "Don't forget to check spam for reply", "success");
        },
        (error) => {
          swal("Oh noes!", "Something went wrong!", "error");
        }
      );
  };
  return (
    <div style={{ margin: "10rem 0" }}>
      <Grid>
        <Card
          style={{
            maxWidth: 700,
            padding: "20px 5px",
            margin: "0 auto",
            background: "#D8DEE9",
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="h5" align="center">
              Contact Us
            </Typography>
            <form ref={form} onSubmit={sendEmail}>
              <Grid container spacing={1}>
                <Grid xs={12} item>
                  <TextField
                    color="success"
                    placeholder="Enter name"
                    label="name"
                    name="name"
                    variant="outlined"
                    fullWidth
                    required
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    color="success"
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    label="email"
                    variant="outlined"
                    fullWidth
                    required
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    color="success"
                    type="number"
                    placeholder="Enter phone number"
                    label="phone"
                    name="phone"
                    variant="outlined"
                    fullWidth
                    required
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    color="success"
                    multiline
                    rows={4}
                    placeholder="Type your message here"
                    variant="outlined"
                    fullWidth
                    required
                    label="message"
                    name="message"
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
};

export default Contact;
